import { Redirect } from "react-router-dom";
import { useAppSelector } from "../../../hooks/storeHooks";
import { useForm } from "../../../hooks/useForm";
import { login } from "../../../services/auth";
import logo from "../../../assets/GeoSinergiaLogo.svg";
import { Input, InputAdornment } from "@material-ui/core";
import { mdiEmail, mdiKey } from "@mdi/js";
import Icon from "@mdi/react";
import { mainColor } from "../../../styles/styleVars.js";
import React from "react";
import "./logoAnimation.css";
import { withError } from "../../../services/snackbarNotification";
import { setAllSockets } from "../../../services/flower";

const VERSION_STRING = process.env.REACT_APP_VERSION_STRING

const LoginView: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [values, _, handleChange] = useForm({
    username: "",
    password: "",
  });

  const isAuthenticated = useAppSelector((s) => !!s.authReducer.token);

	return isAuthenticated ? (
		<Redirect to='/' />
	) : (
		<div className='flex items-center h-screen justify-around bg-gradient-to-b from-gray-100 to-gray-300 tracking-wider'>
			<div className='hidden md:flex md:w-1/2 h-full items-center justify-center'>
				<div className='w-96 h-96 bg-main-1 rounded-full p-12 fadeIn'>
					<img src={logo} alt='' className='fadeIn' />
				</div>
			</div>
			<div className='flex flex-col items-center w-full md:w-1/2 h-full justify-center space-y-4'>
				<div className='relative w-512 text-7xl h-1/3 text-main-1 font-bold'>
					<div className='absolute text-7xl w-full text-center h-full logoText1 flex justify-center items-center top-1/2  text-main-1'>
						Optical Monitoring System
					</div>
					<div className='absolute   text-9xl w-full h-full  logoText2 text-center flex flex-col justify-center items-center  text-main-1 '>
						OMS
						<br />
						<div className='text-lg'>
							{ VERSION_STRING }
						</div>
						<br />
						<div className='text-lg'>
							Américo Vespucio Oriente II
						</div>
					</div>
				</div>
				<form
					className='w-8/12 h-2/3'
					onSubmit={(e) => {
						e.preventDefault()
						login(values.username, values.password)
						setAllSockets()
						// setFlowerWebSocket()
					}}
				>
					<div className='bg-white rounded-xl shadow-sm pt-10 pb-2 px-10 flex flex-col dropInDelayed'>
						<div className='text-main-1 text-center pt-3 text-l'>
							INICIO DE SESIÓN
						</div>
						<Input
							type='text'
							placeholder='Usuario'
							id='username'
							value={values.username}
							className='mt-8 w-8/12 mx-auto'
							onChange={handleChange}
							endAdornment={
								<InputAdornment position='end'>
									<Icon
										path={mdiEmail}
										title=''
										size={1}
										color={mainColor}
									/>
								</InputAdornment>
							}
						/>

            <Input
              type="password"
              placeholder="Contraseña"
              id="password"
              value={values.password}
              className="mt-8 w-8/12 mx-auto"
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <Icon
                    path={mdiKey}
                    title="Password"
                    size={1}
                    color={mainColor}
                  />
                </InputAdornment>
              }
            />

            <div className="flex justify-center">
              <div className="flex-shrink">
                <button
                  className="mt-3 rounded-full bg-main-1 text-white relative top-8 px-8 py-4"
                  onClick={withError(() =>
                    login(values.username, values.password)
                  )}
                >
                  Ingresar
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default LoginView;
